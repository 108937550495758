.container {
  border-bottom: 1px solid var(--offWhite);
}

.container:last-child {
  border-bottom: none;
}

.link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.withSummary {
  padding: 20px 0 0 0;
}

.logo {
  max-height: 2rem;
}

.externalReferencesAngle {
  width: 30px;
  color: var(--alto);
}
