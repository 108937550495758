.container {
  padding: 80px 20px 20px;
  background: var(--white);
  border-bottom: solid 5px #594a9b;
  border-top: solid 1px #d3d3d3;
  display: flex;
  flex-direction: column;
}

.imagesContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
