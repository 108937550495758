.root {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  width: auto;
  height: 60px;
  background-color: var(--white);
  border-bottom: 1px solid var(--darkWhite);
}

.contentHolder {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: calc(100% - 60px);
}

.content {
  display: flex;
  width: 100%;
  background-color: var(--midWhite);
  overflow: auto;
}

.projectsDropdown {
  margin-right: 30px;
}

.switchPartnerModal {
  width: 400px;
  flex-direction: column;
}

.savePartnerButton {
  margin-top: 10px;
  width: 120px;
  align-self: flex-end;
}
