.container {
  display: flex;
  width: 100%;
  padding: 22px 40px;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  width: 236px;
  margin-right: 37px;
}

.reportsContainer {
  display: flex;
  flex-direction: column;
  width: calc(100% - 236px);
  overflow: auto;
  padding-bottom: 24px;
}

.breadcrumbs {
  margin-bottom: 38px;
  cursor: pointer;
  margin-left: -6px;
}

.breadcrumbsIcon {
  color: var(--pink);
}

.breadcrumbsText {
  color: var(--black);
  opacity: 0.75;
}

.reportListHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}
