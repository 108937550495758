.reportSearch:global(.ui.search) {
  flex: 1;
}

.reportSearch:global(.ui.search) .results {
  width: 100%;
}

:global(.results) {
  max-height: 360px;
  overflow: auto;
}

.result {
  display: flex;
}

.draftResult {
  color: var(--red);
  margin-right: 10px;
}

.templateResult {
  justify-self: flex-end;
  margin-left: auto;
}

.formField {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formFieldLabel {
  margin-bottom: 8px;
  font-size: 13px;
}

.formFieldInput {
  height: 40px;
  font-size: 14px;
  width: 100%;
}
