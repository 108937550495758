.result {
  width: auto;
  display: flex;
}

.name {
  line-height: 34px;
  margin-left: 20px;
}

.search {
  width: 100%;
}

:global(.ui.loading.search .input > i.icon::after),
:global(.ui.loading.search .input > i.icon::before) {
  left: unset;
  right: 30px;
}
