.pageContainer {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
}

.pageTitle {
  margin: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 73px;
  padding-left: 19px;
  margin-bottom: 3px;
  object-fit: contain;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  background-color: #f7f6f6;
}

.pageTitle > h2 {
  font-size: 24px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: #1d1d26;
}
.contentContainer {
  flex-grow: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.contentHeader {
  display: flex;
  justify-content: flex-start;
  padding-left: 40px;
  align-items: center;
  min-height: 60px;
  background-color: #f8f8f8;
  object-fit: contain;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  vertical-align: middle;
}

.contentBody {
  flex: 1;
  margin: 35px 20px 0 40px;
  max-height: 70vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.contentBodyLeft {
  display: flex;
  flex: 1;
}

.orderInformation {
  flex: 1;
  overflow: auto;
  flex-direction: column;
}

.orderInfoListItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 15px 0 15px 0;
}

.orderInfoListItem > div:first-child {
  width: 100px;
  min-width: 100px;
  font-weight: normal;
}

.orderInfoListItem > div:last-child {
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #000;
}

.testsListItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.testsListItem > div:first-child {
  min-width: 60px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #000;
}

.contentBodyRight {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.orderSubmissionTypeHeader {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 0 15px 0;
}

.orderSubmissionTypeHeader > div:first-child {
  width: 150px;
  min-width: 100px;
  font-weight: normal;
}

.orderSubmissionTypeHeader > div:last-child {
  flex: 1;
}

.orderSubmissionTypePatientPurchase {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 15px 0 15px 0;
  overflow: auto;
}

.orderSubmissionTypeLab {
  max-width: 80%;
  margin-left: 150px;
  text-align: justify;
}

.orderSubmissionTypePatientPurchase > div:first-child {
  width: 150px;
  min-width: 100px;
  font-weight: normal;
}

.orderSubmissionTypePatientPurchase > div:last-child {
  flex: 1;
  text-align: justify;
  max-width: 400px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #000;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  object-fit: contain;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  min-height: 77px;
  background-color: #f7f6f6;
}

.editButton {
  font-size: 14px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #d76acd;
}

.cancelButton > button {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: right;
  color: #1d1d26;
}

.submitButton {
  margin-right: 25px;
}
