.row {
  display: flex;
  height: 100px;
  width: 100%;
  padding: 16px;
  flex: 1 0;
}

.column {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: flex-start;
  height: 100%;
}

.name,
.id,
.providerName,
.date,
.status {
  color: var(--black);
  opacity: 0.6;
  font-size: 14px;
  margin-top: 4.6px;
}

.statusText {
  text-transform: capitalize;
}

/* Summary */
.summaryContainer {
  display: flex;
  align-self: flex-end;
}

.summaryCircle {
  width: 33.6px;
  height: 33.6px;
  display: flex;
  border-radius: 84px;
  justify-content: center;
  align-items: center;
}

.summaryCircleNormal {
  border: solid 1.7px #50d3c2;
}

.summaryCircleAbnormal {
  border: solid 1.7px #f36;
}

.summaryCircle:not(:last-child) {
  margin-right: 6.4px;
}

/* Priority */
.row .priorityUrgent {
  border-left: 6px solid red;
}

.priorityPartialLong {
  border-color: yellow;
}

.priorityEmptyWaiting {
  border-color: #60d4ff;
}

.priorityPartialShort {
  border-color: green;
}

/* New */
.new {
  background: #e6f8ff;
}

.notNew {
  background: white;
}

/* Released Status */
.releasedStatus {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.releasedStatusText {
  font-size: 10px;
  margin-right: 6px;
}
