.container {
  padding: 17px 20px;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--doveGray);
}

.text {
  margin-top: 15px;
  color: var(--black);
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.icon {
  color: var(--doveGray);
}
