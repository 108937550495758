.container {
  font-size: 15px;
  line-height: 26px;
  color: var(--lightenBlack);
}

.name {
  font-size: 30px;
  color: var(--black);
  margin-bottom: 0;
}

.clientIdContainer {
  margin: 16px 0;
}

.clientId {
  color: var(--lightenBlack);
}

.profilePhoto {
  margin: 10px 0 17px;
}

.gender {
  text-transform: capitalize;
}

.editLink {
  color: var(--pink);
  font-size: 13px;
  font-weight: 900;
}
