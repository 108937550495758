.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 22px 40px 20px 40px;
}

.header {
  margin-bottom: 18px;
}

.formContainer:global(.ui.segment) {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 34px 30px;
}

.panels {
  display: flex;
  flex: 1;
}

.leftPanel {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 22px;
}

.createdDate {
  margin-bottom: 20px;
}

.rightPanel {
  display: flex;
  flex: 4;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
}

.deleteButton {
  color: var(--red);
  margin-right: auto;
  padding-left: 0;
}
