.titleAndFilterContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 36px;
}

.title {
  font-size: 24px;
  flex: 1 1 auto;
  margin: 0;
}

.filters {
  display: flex;
}

.filters:global(.icon) {
  opacity: 0.3;
}

.filters:global(.ui.input) {
  width: 315px;
}

.sortBy {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.dropdown {
  margin: 0 0 0 10px;
  min-width: 130px;
}

.sortByLabel {
  opacity: 0.5;
}

.container {
  background-color: var(--midWhite);
}

.ctaButton {
  animation: buttonScale 300ms;
  animation-fill-mode: forwards;
  float: right;
  white-space: nowrap;
}

@media only screen and (max-width: 992px) {
  .title {
    width: 100%;
    margin-bottom: 20px;
  }

  .titleAndFilterContainer,
  .head {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .filters {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .filters {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .sort-by {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

@keyframes buttonScale {
  0% {
    width: 0;
  }
  100% {
    width: 200px;
  }
}
