.breadcrumbs {
  display: flex;
  cursor: pointer;
  color: rgba(var(--rgbBlack), 0.7);
}

.breadcrumbs:hover {
  color: rgba(var(--rgbBlack), 0.9);
}

.icon {
  color: var(--pink);
}
