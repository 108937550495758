.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 22px 40px 0 40px;
}

.mainHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

/* Breadcrumbs */
.breadcrumbs {
  margin-bottom: 38px;
}

.caretIcon {
  color: var(--pink);
}

.breadcrumbsText {
  color: var(--doveGray);
}

.backButtonContainer {
  font-size: 18px;
  display: flex;
}

/* Left Panel */
.leftPanel {
  min-width: 224px;
  max-width: 224px;
}

.reportNotes {
  margin-top: 50px;
}

.reportNotesTitle {
  font-size: 20px;
  margin: 0;
}

.notesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

/* Right Panel */
.backIcon:global(.svg-inline--fa) {
  width: 20px;
  padding-right: 10px;
}

.label {
  font-size: 14px;
  margin-bottom: 5px;
}

.referenceText {
  font-size: 16px;
  line-height: 1;
}

.removeMarginBottom {
  margin-bottom: -14px;
}
