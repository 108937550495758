.chartContainer {
  position: relative;

  /* Need to be this width, otherwise, the container will only resize for bigger sizes */
  width: 0;
  min-width: 600px;
}

.chart {
  background: var(--white);
}

.circle {
  cursor: pointer;
}

.tooltipArrow::before {
  top: 100%;
  left: 50%;
  border: 5px solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: #594a9b;
  margin-left: -6px;
}
