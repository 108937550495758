@import '~@labsavvyapp/ui-components/lib/theme/theme-variables.css';

.container {
  display: flex;
  flex: 1;
}

.subPageContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 20px;
  background: var(--white);
}
