.title {
  padding: 20px 20px 0;
}

.questions {
  margin: 0;
  padding: 20px;
  padding-left: 45px;
}

.question {
  font-size: 15px;
  font-weight: 300;
}
