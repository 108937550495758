.container {
  display: flex;
  flex-direction: column;
}

.reportList {
  margin-top: 16px;
  flex-direction: column;
  flex-shrink: 0;
}

.containerLoader {
  margin: auto;
}

.containerLoader > .wrapperLoader {
  position: absolute;
}
