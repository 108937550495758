/* List */
.list {
  padding: 0 20px;
}

.listHeader {
  padding-left: 36px;
}

.listRow {
  min-height: 64px;
  margin-bottom: 10px;
  color: var(--textColor);
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 0 20px;
  position: relative;
}

.listRow::after {
  display: block;
  content: ' ';
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  width: 5px;
  height: auto;
  background: var(--offWhite);
}

.listRow:hover {
  background: var(--blue);
}

.listRefreshLink {
  color: var(--pink);
  text-decoration: underline;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  text-align: inherit;
}

/* Margins */
.marginLeft10 {
  margin-left: 10px;
}

.marginTop30 {
  margin-top: 30px;
}

.marginRight10 {
  margin-right: 10px;
}

/* Typography */
.pageTitle {
  font-size: 24px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: var(--black);
}
