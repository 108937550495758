.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1 0 auto;
}

.logo {
  height: 110px;
  margin-bottom: 36px;
  opacity: 15%;
}

.message {
  font-size: 24px;
  color: var(--doveGray);
  margin-bottom: 12px;
}
