.container {
  display: flex;
  flex-direction: column;
  height: auto;
}

.userInfoContainer {
  display: flex;
}

.photo {
  width: 34px;
  height: 34px;
  border-radius: 34px;
  position: relative;
  z-index: 2;
}

.nameInitials {
  z-index: 10;
}

.name {
  font-size: 11px;
  letter-spacing: 0.25px;
  color: var(--manatee);
  margin-left: 6px;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  margin: -25px auto 0 10px;
  max-width: 500px;
}

.sender .messageContainer {
  margin: 0;
  margin-left: auto;
}

.message {
  text-align: left;
  font-size: 15px;
  line-height: 1.33;
  font-weight: 300;
  color: var(--black);
  margin: 8px 0 0 8px;
  padding: 12px 16px;
  border: 0.5px solid var(--silver);
}

.sender .message {
  border-radius: 8px;
  background-color: var(--midWhite);
}

.receiver .message {
  border-radius: 8px;
  background-color: var(--lilyWhite);
}

.date {
  font-size: 10px;
  letter-spacing: 0.25px;
  color: var(--dustyGray);
  text-transform: uppercase;
  text-align: right;
}

.receiver .date {
  margin-left: 8px;
  text-align: left;
}
