.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto !important;
  column-fill: auto;
}

.header {
  height: 73px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: var(--midWhite);
  border-bottom: 1px solid var(--alto);
  position: relative;
  box-shadow: 0 2px 3px 0 var(--alto);
}

.centerContainer {
  display: flex;
  flex: 1;
  justify-content: center;
}

.title {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: var(--black);
}

.card {
  width: 351px;
  min-height: 240px;
  margin: 20px;
  padding: 20px;
  border-radius: 4px;
  border: solid 1px #ddd;
}

.rowDetailsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
}

.packageTitle {
  font-size: 14px;
  font-weight: bold;
}

.packageDetails {
  font-size: 14px;
}

.frequentTitle {
  flex: 1;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-left: 20px;
}

.viewAll {
  margin: 0;
  padding-right: 30px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #594a9b;
  cursor: pointer;
}

.packageListContainer {
  display: flex;
  flex-direction: row;
  padding: 13px;
  border-radius: 2px;
  border-top: solid 1px #ddd;
  border-right: solid 1px #ddd;
  border-bottom: solid 1px #ddd;
  border-left: solid 5px #ddd;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.packageName {
  display: flex;
  flex: 1;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.packageDetailsContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.packageLabel {
  display: flex;
  flex: 1;
  font-size: 14px;
  margin: 0;
  text-align: center;
}

.copyLinkLabel {
  cursor: pointer;
  display: flex;
  flex: 1;
  font-size: 14px;
  color: #594a9b;
}

.viewLabel {
  cursor: pointer;
  font-size: 14px;
  color: #594a9b;
  margin-right: 5px;
}
