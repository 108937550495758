.container {
  display: flex;
}

.container .row {
  border: 0;
  height: auto;
  margin: 0;
  cursor: auto;
}

.invoiceColumn {
  margin: 0 10px;
}

.viewInvoiceButton {
  color: var(--grey);
}

.viewInvoiceButton:link {
  color: var(--dodgerBlue);
}

.dateColumn {
  width: 85px;
}
