.container {
  display: flex;
  min-width: 300px;
}

.bankDetails {
  flex: 1;
  line-height: 25px;
  display: flex;
  flex-direction: column;
}

.actions {
  width: 50px;
}

.reset {
  cursor: pointer;
  padding: 15px;
}
