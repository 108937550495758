.chartTitle {
  margin: 0;
}

.chartUnits {
  font-size: 16px;
  margin-left: auto;
  align-self: center;
  color: rgba(29, 29, 38, 0.41);
}
