.container {
  padding: 27px 20px;
  background-color: rgba(255, 17, 17, 0.1);
  border-bottom: 5px solid #fe3824;
  margin-bottom: 16px;
}

.footer {
  padding-top: 10px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: var(--black);
}
