@keyframes showHideDot {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.loadingDots {
  display: flex;
  text-align: center;
  align-items: flex-end;
}

.dot {
  display: inline;
  margin-left: 0.2em;
  margin-right: 0.2em;
  position: relative;
  font-size: 14px;
  opacity: 0;
  animation: showHideDot 2.5s ease-in-out infinite;
}

.dot.dotOne {
  animation-delay: 0.2s;
}

.dot.dotTwo {
  animation-delay: 0.4s;
}

.dot.dotThree {
  animation-delay: 0.6s;
}
