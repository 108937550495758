@import '~@labsavvyapp/ui-components/lib/theme/theme-variables.css';
@import '~@labsavvyapp/ui-components/lib/theme/theme.css';
@import '~@labsavvyapp/ui-components/lib/index.css';

html,
body,
#root {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

/* TODO: Investigate these overrides */
.ui.grid {
  flex-direction: column;
}
