.container {
  padding-top: 16px;
}

.subTitle {
  background: var(--midWhite);
}

.info {
  padding: 30px 20px;
}
