.listHeader {
  margin-bottom: 10px;
  background: var(--grayedWhite);
}

.name,
.patient_id {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.name {
  flex: 1 0 120px;
}

.patient_id {
  flex: 0 0 100px;
}

@media only screen and (min-width: 800px) {
  .patient_id {
    flex: 0 0 120px;
  }
}

.dob {
  flex: 0 0 120px;
}

.photo {
  padding: 3px;
  flex: 0 0 45px;
}

.rowPhoto {
  padding: 3px;
  flex: 0 0 45px;
  margin-right: 9px;
}

.age {
  flex: 0 0 60px;
}

.gender {
  flex: 0 0 80px;
}

.arrow {
  flex: 0 0 40px;
  text-align: right;
  font-size: 20px;
  color: var(--alto);
}

.arrow i {
  margin: -11px;
  padding-top: 3px;
}
