.row {
  display: flex;
  flex-flow: column nowrap;
  padding: 15px 20px;
  height: auto;
}

.nameDateColumn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12.7px;
  font-size: 12px;
  color: var(--lightenBlack);
}

.note {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.linkContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
