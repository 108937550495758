.container {
  display: flex;
  flex: 1 0;
  flex-direction: column;
}

.panels {
  display: flex;
  flex: 1 0;
}
