.container {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  margin-top: 37px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reportList {
  margin-top: 16px;
}
