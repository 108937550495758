.container {
  display: flex;
  flex: 1;
  padding: 22px 40px 0;
}

/* Header */
.headerRightPanel {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
}

.sortByLabel {
  margin-right: 10px;
}

.resultReportLink {
  display: flex;
  margin-right: 30px;
}

.pdfIcon {
  width: 26px;
  height: 23px;
  align-self: center;
}

.releaseResultsButton {
  margin-left: 25px;
}

/* Panel left */
.reportNotesHeader {
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
  align-items: center;
}

.reportNotesTitle {
  font-size: 20px;
  margin: 0;
}

.notesContainer {
  margin-top: 20px;
}

.reportNotes {
  padding: 20px;
}

.leftPanel {
  min-width: 224px;
  max-width: 224px;
}

.messagesContainers {
  cursor: pointer;
  display: block;
}

.messageButton {
  margin: 0 25px;
}

.ctasModal {
  width: 100%;
  flex-direction: column;
}
